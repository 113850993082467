<!--会员设置-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">会员设置</span>
      </div>
      <div>

      </div> 
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <el-form ref="form" :model="form" label-width="170px">
        <div style="border-bottom: 1px solid #EEEEEE;">
          <div class="xuanxianglist">
            <div v-for="(item,index) in list" :key="index" class="kuai">
              <div class="xuanxianglistactive"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
     
        <div style="margin-top: 20px;text-align: right;">
          <el-button type="primary" @click="Add()"  v-auths="[`${$config.uniquePrefix}operate:memberSettings:save`]"  >添加</el-button>
        </div>
        <div style="border: 1px solid #d8dadc;border-radius: 10px;padding: 20px;margin-top: 20px;" v-for="(item,index) in form.custom" :key="index">
          <div>
            <span style="font-size: 20px;font-weight: 700;color: #ff9b05;">
              <el-input clearable placeholder="请输入会员名称" v-model="item.name" style="width: 280px!important;"></el-input>
            </span>
          </div>
          <el-row :gutter="15" style="margin-top: 20px;display: flex;align-items: center;">
            <el-col :span="4">
              <div style="display: flex;align-items: center;">
                <span style="width: 6px;height: 17px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 16px;font-weight: 700;color: #3f4157;">会员费</span>
              </div>
            </el-col>
            <el-col :span="4" v-for="(it,idx) in item.vip_day" :key="idx">
              <div style="display: flex;align-items: center;">
                <span style="font-size: 16px;font-weight: 400;color: #3f4157;display: inline-block;width: 45px;">{{it.day}}年</span>
                <el-input clearable placeholder="请输入" v-model="it.price" style="width: 280px!important;">
                  <template slot="append">.元</template>
                </el-input>
              </div>
            </el-col>
            <!--<el-col :span="4">-->
            <!--  <div style="display: flex;align-items: center;">-->
            <!--    <span style="font-size: 16px;font-weight: 400;color: #3f4157;display: inline-block;width: 45px;">2年</span>-->
            <!--    <el-input clearable placeholder="请输入" v-model="form.input2" style="width: 280px!important;">-->
            <!--      <template slot="append">.元</template>-->
            <!--    </el-input>-->
            <!--  </div>-->
            <!--</el-col>-->
            <!--<el-col :span="4">-->
            <!--  <div style="display: flex;align-items: center;">-->
            <!--    <span style="font-size: 16px;font-weight: 400;color: #3f4157;display: inline-block;width: 45px;">3年</span>-->
            <!--    <el-input clearable placeholder="请输入" v-model="form.input2" style="width: 280px!important;">-->
            <!--      <template slot="append">.元</template>-->
            <!--    </el-input>-->
            <!--  </div>-->
            <!--</el-col>-->
            <!--<el-col :span="4">-->
            <!--  <div style="display: flex;align-items: center;">-->
            <!--    <span style="font-size: 16px;font-weight: 400;color: #3f4157;display: inline-block;width: 45px;">4年</span>-->
            <!--    <el-input clearable placeholder="请输入" v-model="form.input2" style="width: 280px!important;">-->
            <!--      <template slot="append">.元</template>-->
            <!--    </el-input>-->
            <!--  </div>-->
            <!--</el-col>-->
            <!--<el-col :span="4">-->
            <!--  <div style="display: flex;align-items: center;">-->
            <!--    <span style="font-size: 16px;font-weight: 400;color: #3f4157;display: inline-block;width: 45px;">5年</span>-->
            <!--    <el-input clearable placeholder="请输入" v-model="form.input2" style="width: 280px!important;">-->
            <!--      <template slot="append">.元</template>-->
            <!--    </el-input>-->
            <!--  </div>-->
            <!--</el-col>-->
          </el-row>

          <el-row :gutter="15" style="margin-top: 20px;display: flex;align-items: center;">
            <el-col :span="4">
              <div style="display: flex;align-items: center;">
                <span style="width: 6px;height: 17px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 16px;font-weight: 700;color: #3f4157;">会员权益</span>
              </div>
            </el-col>
            <el-col :span="20">
              <el-checkbox v-for="(it1,index) in item.rights" v-model="it1.switch" :label="it1.label" :key="index"></el-checkbox>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="6" v-for="(it2,index) in item.data" :key="index">
              <el-form-item :label="it2.label">
                <el-input
                  placeholder="请输入"
                  v-model="it2.values"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div style="text-align: right;">
            <el-popconfirm title="确定执行此操作吗？" @confirm="del(item,index)">
              <el-button slot="reference" type="danger" v-auths="[`${$config.uniquePrefix}operate:memberSettings:delete`]" >删除</el-button>
            </el-popconfirm>
          </div>
        </div>

        <div style="border: 1px solid #d8dadc;border-radius: 10px;margin-top: 20px;padding: 20px;">
          <div>
            <span style="font-size: 20px;font-weight: 700;color: #000000;">免费会员</span>
          </div>

          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="8">
              <el-form-item label="提现到账时间（天）：" label-width="180px">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.accounting_date"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话时长（分钟）：">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.minimum_amount"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话（元/分钟）：">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.service_charge"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="8">
              <el-form-item label="提现最低金额（元）：" label-width="180px">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.call_count"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="短信（元/条）：">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.sns_count"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="短信（条）：">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.voice_count"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="8">
              <el-form-item label="提现手续费（元）：" label-width="180px">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.call_price"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="语音提醒（次）：">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.sns_price"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="语音提醒（元/次）：">
                <el-input
                  placeholder="请输入"
                  v-model="form.free.voice_price"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
          v-auths="[`${$config.uniquePrefix}operate:memberSettings:put`]"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
import {getmember_center, Detailsmember_centers, Savemember_centers, Delmember_centers} from "@/api/operate";

export default {
  data(){
    return{
      form:{
        free:{
          accounting_date:'',
        }
      },

      //数据
      list:[
        {
          name:'平台会员'
        },
        {
          name:'C端司机会员'
        },
      ],
      isShow:0,

      their_type:'',

      //用户添加
      form1:{
        "custom": {
          "name": "",
          "vip_day": [
            {
              "day": 1,
              "price": ""
            },
            {
              "day": 2,
              "price": ""
            },
            {
              "day": 3,
              "price": ""
            },
            {
              "day": 4,
              "price": ""
            },
            {
              "day": 5,
              "price": ""
            }
          ],
          "rights": [
            {
              "switch": false,
              "label": "实时结算"
            },
            {
              "switch": false,
              "label": "提现免手续费"
            },
            {
              "switch": false,
              "label": "没有最低提现金额限制"
            }
          ],
          "config": [
            {
              "switch": false,
              "indexes": "",
              "label": "支持共享司机派单"
            },
            {
              "switch": false,
              "indexes": "",
              "label": "支持平台服务商派单"
            },
            {
              "switch": false,
              "indexes": "",
              "label": "支持抢单(C端司机显示)"
            }
          ],
          "data": [
            {
              "indexes": "call_count",
              "label": "电话时长（分钟）",
              "values": ""
            },
            {
              "indexes": "sns_count",
              "label": "短信（条）",
              "values": ""
            },
            {
              "indexes": "voice_count",
              "label": "语音提醒（次）",
              "values": ""
            },
            {
              "indexes": "call_price",
              "label": "电话（元/分钟）",
              "values": ""
            },
            {
              "indexes": "sns_price",
              "label": "短信（元/条）",
              "values": ""
            },
            {
              "indexes": "voice_price",
              "label": "语音提醒（元/次）",
              "values": ""
            }
          ]
        }
      },

    }
  },

  mounted() {
    //获取详情
    this.getDetails();
  },

  methods:{
    //获取配置
    getConfig(){
      getmember_center().then(res => {
        console.log('新的res',res);
        this.form = res.data;
      })
    },

    //获取详情
    getDetails(){
      if(this.isShow === 0){
        this.their_type = 'platform'
      }else {
        this.their_type = 'C'
      }
      let where = {
        their_type:this.their_type
      }
      Detailsmember_centers(where).then(res => {
        console.log("res2",res);
        if(res.data){
          this.form = res.data
        }else {
          //调用获取配置
          this.getConfig();
        }
      }).catch(e => {
        console.log(e)
      })
    },

    //选择类型
    activeItem(item,index){
      this.isShow = index;
      if(this.isShow === 0){
        this.their_type = 'platform'
      }else {
        this.their_type = 'C'
      }
      this.getDetails();
    },

    //添加
    Add(){
      // console.log(this.form1);
      this.form.custom.push(this.form1.custom);
    },

    //删除
    del(item,index){
      if(item.id == undefined){
        this.form.custom.splice(index,1);
      }else {
        Delmember_centers(item.id).then(res => {
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.getDetails();
          }else {
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      }
    },

    //保存
    save(){
      if(this.isShow === 0){
        this.their_type = 'platform'
      }else {
        this.their_type = 'C'
      }
      this.form.their_type = this.their_type;
      Savemember_centers(this.form).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

  },

}
</script>

<style lang="scss" scoped>
.xuanxianglist{
  .kuai{
    margin: 10px;
    display: inline-block;
  }
  .xuanxianglistactive{
    cursor: pointer;
    background: #E8E8E8;
    border: 1px solid #dadada;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .xuanxianglistactive:hover{
    background: #FF9B05;
    border: 1px solid #FF9B05;
    border-radius: 6px;
    color: #FFFFFF;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    border: 1px solid #FF9B05;
    border-radius: 6px;
    color: #FFFFFF;
  }
}
</style>
